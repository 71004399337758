// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'assets/scss/bootstrap-utils';
@import 'assets/scss/variables';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';

@import 'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css';
@import "~@ng-select/ng-select/themes/material.theme.css";

// Be sure that you only ever include this mixin once!
@include mat.core();

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900%7cPoppins:100,200,300,400,500,600,700,800,900&amp;display=swap');

$jchn-primary:  mat.define-palette($front-end-primary);
$jchn-accent:  mat.define-palette($front-end-accent, A200, A100, A400);
$jchn-warn:  mat.define-palette($front-end-warn);

$jchn-typography: mat.define-typography-config(
  $font-family:
    '"Montserrat", -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica", Arial, sans-serif'
);

$jchn-theme: mat.define-light-theme(
  (
    color: (
      primary: $jchn-primary,
      accent: $jchn-accent,
      warn: $jchn-warn
    ),
    typography: $jchn-typography
  )
);

@include mat.core-theme($jchn-theme);
@include mat.all-component-themes($jchn-theme);



* {
  transition: background 0.25s ease-in, color 0.25s ease-in;
  font-family: Montserrat, Segoe UI, Arial Font Family, Calibri, sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

h1 {
  font-size: 1.5rem;

}

h1, h3 {
  color: #434343;
}

.page-header {
  text-align: center;
  padding: 20px;
}

body,
p {
  margin: 0;
}

.alert-error {
  background-color: Red;
}

.alert-success {
  background-color: #DFF2BF;
}

.alert-warn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;

}

.color-primary {
  color: var(--primary-500);
}

.bg-primary {
  background: var(--primary-500);
}

.set-height {
  min-height: calc(100vh - calc(#{$header-height} + #{$footer-height}));
}


formly-field-mat-multicheckbox, formly-field-multi-checkbox-with-input {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 75%;
}

@media(max-width: 1200px) {
  formly-field-mat-multicheckbox, formly-field-multi-checkbox-with-input {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

mat-radio-button {
  padding: 3px;
  margin: 5px;
}

.mat-expansion-panel-header-title {
  color: #434343;
}

.formly-loader {
  formly-field-mat-select::after {
    content: '';
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 5px;
    right: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

mat-form-field.no-padding .mat-form-field-wrapper, formly-field.no-padding formly-wrapper-mat-form-field mat-form-field .mat-form-field-wrapper {
  padding: 0;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 {    border-top: 0 !important; }
    .border#{$infix}-right-0 {  border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 {   border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 84px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-row, mat-header-row, mat-footer-row {
  align-items: stretch !important;
}

.file-input {
  display: none;
}


.snack-error {
  background-color: red;
  color: white;
  text-align: center;
  .mat-simple-snackbar-action {
    color: white !important;
  }
}


.snack-success {
  background-color: #14c314;
  color: white;
  text-align: center;
}

.mat-dialog-panel-p-0 .mat-dialog-container {
  padding: 0;
}


.disable-clicks {
  pointer-events: none;
}

.mat-tooltip.tooltip {
  font-size: 1rem;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--primary-500) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: var(--primary-500) !important;
}
