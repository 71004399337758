:root {
  --background: #edf2f9;
  --text: #617185;

  --primary-50 : #faf1e2;
  --primary-100 : #f2ddb6;
  --primary-200 : #eac685;
  --primary-300 : #e1af54;
  --primary-400 : #da9d2f;
  --primary-500 : #d48c0a;
  --primary-600 : #cf8409;
  --primary-700 : #c97907;
  --primary-800 : #c36f05;
  --primary-900 : #b95c03;
  --primary-a100 : #ffefe2;
  --primary-a200 : #ffd2af;
  --primary-a400 : #ffb57c;
  --primary-a700 : #ffa663;
  --primary-contrast-50: #000000;
  --primary-contrast-100: #000000;
  --primary-contrast-200: #000000;
  --primary-contrast-300: #000000;
  --primary-contrast-400: #000000;
  --primary-contrast-500: #ffffff;
  --primary-contrast-600: #ffffff;
  --primary-contrast-700: #ffffff;
  --primary-contrast-800: #ffffff;
  --primary-contrast-900: #ffffff;
  --primary-contrast-a100: #000000;
  --primary-contrast-a200: #000000;
  --primary-contrast-a400: #000000;
  --primary-contrast-a700: #000000;

  --accent-50: #eeeeee;
  --accent-100: #d5d5d5;
  --accent-200: #b9b9b9;
  --accent-300: #9c9c9c;
  --accent-400: #878787;
  --accent-500: #727272;
  --accent-600: #6a6a6a;
  --accent-700: #5f5f5f;
  --accent-800: #555555;
  --accent-900: #424242;
  --accent-a100: #f6abab;
  --accent-a200: #f17d7d;
  --accent-a400: #ff3b3b;
  --accent-a700: #ff2222;
  --accent-contrast-50: #000000;
  --accent-contrast-100: #000000;
  --accent-contrast-200: #000000;
  --accent-contrast-300: #000000;
  --accent-contrast-400: #000000;
  --accent-contrast-500: #000000;
  --accent-contrast-600: #000000;
  --accent-contrast-700: #000000;
  --accent-contrast-800: #ffffff;
  --accent-contrast-900: #ffffff;
  --accent-contrast-a100: #000000;
  --accent-contrast-a200: #000000;
  --accent-contrast-a400: #000000;
  --accent-contrast-a700: #000000;

  --warn-50: #fce7eb;
  --warn-100: #f8c3cd;
  --warn-200: #f39bab;
  --warn-300: #ee7389;
  --warn-400: #ea5570;
  --warn-500: #e63757;
  --warn-600: #e3314f;
  --warn-700: #df2a46;
  --warn-800: #db233c;
  --warn-900: #d5162c;
  --warn-a100: #ffffff;
  --warn-a200: #ffd3d7;
  --warn-a400: #ffa0a8;
  --warn-a700: #ff8691;
  --warn-contrast-50: #000000;
  --warn-contrast-100: #000000;
  --warn-contrast-200: #000000;
  --warn-contrast-300: #000000;
  --warn-contrast-400: #000000;
  --warn-contrast-500: #ffffff;
  --warn-contrast-600: #ffffff;
  --warn-contrast-700: #ffffff;
  --warn-contrast-800: #ffffff;
  --warn-contrast-900: #ffffff;
  --warn-contrast-a100: #000000;
  --warn-contrast-a200: #000000;
  --warn-contrast-a400: #000000;
  --warn-contrast-a700: #000000;
}

$front-end-primary: (
  50 : #f2ddb6,
  100 : #f2ddb6,
  200 : #eac685,
  300 : #e1af54,
  400 : #da9d2f,
  500 : #d48c0a,
  600 : #cf8409,
  700 : #c97907,
  800 : #c36f05,
  900 : #b95c03,
  A100 : #ffefe2,
  A200 : #ffd2af,
  A400 : #ffb57c,
  A700 : #ffa663,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$front-end-accent: (
  50 : #eeeeee,
  100 : #d5d5d5,
  200 : #b9b9b9,
  300 : #9c9c9c,
  400 : #878787,
  500 : #727272,
  600 : #6a6a6a,
  700 : #5f5f5f,
  800 : #555555,
  900 : #424242,
  A100 : #f6abab,
  A200 : #f17d7d,
  A400 : #ff3b3b,
  A700 : #ff2222,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$front-end-warn: (
  50: var(--warn-50),
  100: var(--warn-100),
  200: var(--warn-200),
  300: var(--warn-300),
  400: var(--warn-400),
  500: var(--warn-500),
  600: var(--warn-600),
  700: var(--warn-700),
  800: var(--warn-800),
  900: var(--warn-900),
  A100: var(--warn-a100),
  A200: var(--warn-a200),
  A400: var(--warn-a400),
  A700: var(--warn-a700),
  contrast: (
    50: var(--warn-contrast-50),
    100: var(--warn-contrast-100),
    200: var(--warn-contrast-200),
    300: var(--warn-contrast-300),
    400: var(--warn-contrast-400),
    500: var(--warn-contrast-500),
    600: var(--warn-contrast-600),
    700: var(--warn-contrast-700),
    800: var(--warn-contrast-800),
    900: var(--warn-contrast-900),
    A100: var(--warn-contrast-a100),
    A200: var(--warn-contrast-a200),
    A400: var(--warn-contrast-a400),
    A700: var(--warn-contrast-a700),
  ),
);

$header-height: 0px;
$footer-height: 0px;
